export const APP_NAME = "Satto";

export const STACK_NAME = "homepage";

// x(twitter)のリンク
export const X_SATTO_LINK = "https://x.com/satto_ai_agent";

export const OTOIAWASE_LINK = "https://osmail.mb.softbank.jp/m?f=9264";

// youtubeのリンク
export const YOUTUBE_LINK = "https://www.youtube.com/@satto_ai_agent";

// satto noteアプリ
export const SATTO_NOTE_APP_PROTOCOL =
  import.meta.env.MODE === "prd"
    ? "satto-note"
    : `satto-note-${import.meta.env.MODE}`;
